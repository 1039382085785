import React, { useState } from "react";
import { PencilSimple } from "@phosphor-icons/react";

import { ScheduleInterval } from "common/types";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";
import { Button } from "common/components/ui/Button";
import Checkbox from "common/components/ui/Checkbox";
import Label from "common/components/ui/Label";

import RangesSelect from "../RangesSelect";

interface EditScheduleIntervalProps {
  day: string;
  ranges: ScheduleInterval[];
  isDisabled: boolean;
  setRanges: (ranges: ScheduleInterval[]) => void;
  onApplyToAll: (ranges: ScheduleInterval[]) => void;
}

export default function EditScheduleInterval({
  day,
  ranges,
  isDisabled,
  setRanges,
  onApplyToAll,
}: EditScheduleIntervalProps) {
  const [currentRanges, setCurrentRanges] = useState(ranges);
  const [shouldApplyToAll, setShouldApplyToAll] = useState(false);
  const isEnabled = !!ranges.length;

  return (
    <Drawer onAfterClose={() => setCurrentRanges(ranges)}>
      <DrawerTrigger asChild>
        <Button
          variant="quaternary-black"
          intent="iconOnly"
          disabled={!isEnabled || isDisabled}
        >
          <PencilSimple />
        </Button>
      </DrawerTrigger>
      <DrawerContent>
        <h5 className="-mx-4 mb-6 border-b border-black-200 px-4 pb-4 text-headline-xl-bold capitalize">
          {day} schedule
        </h5>

        <RangesSelect
          isDisabled={isDisabled}
          ranges={currentRanges}
          setRanges={setCurrentRanges}
        />

        <div className="mt-4 flex items-center gap-1">
          <Checkbox
            id="applyToAll"
            checked={shouldApplyToAll}
            onChange={({ target: { checked } }) => {
              setShouldApplyToAll(checked);
            }}
          />
          <Label htmlFor="applyToAll">Apply to all days</Label>
        </div>

        <DrawerClose asChild>
          <Button
            className="mb-2 mt-8"
            onClick={() => {
              setRanges(currentRanges);
              if (shouldApplyToAll) {
                onApplyToAll(currentRanges);
              }
              setShouldApplyToAll(false);
            }}
          >
            Save and close
          </Button>
        </DrawerClose>
      </DrawerContent>
    </Drawer>
  );
}
