import React from "react";

import useAppStore from "common/hooks/useAppStore";
import RadioButton from "common/components/ui/RadioButton";
import { typedObjectEntries } from "common/helpers/utils";
import { userRoles } from "common/constants";
import Label from "common/components/ui/Label";

export default function SubscriptionRole() {
  const subscriptionRole = useAppStore(
    (state) => state.debugOptions.subscriptionRole,
  );
  return (
    <>
      <span className="my-2 text-caption-12-regular text-black-400">
        Subscription Role
      </span>
      <div className="flex flex-wrap gap-2">
        <div className="flex gap-x-2">
          <RadioButton
            id="defaultRole"
            onChange={() =>
              useAppStore.getState().setDebugOptions({ subscriptionRole: null })
            }
            name="subscriptionRole"
            checked={!subscriptionRole}
          />
          <Label htmlFor="defaultRole">Default</Label>
        </div>
        {typedObjectEntries(userRoles).map(([key, { label }]) => (
          <div className="flex gap-x-2" key={key}>
            <RadioButton
              id={key}
              onChange={() =>
                useAppStore
                  .getState()
                  .setDebugOptions({ subscriptionRole: key })
              }
              name="subscriptionRole"
              checked={subscriptionRole === key}
            />
            <Label htmlFor={key}>{label}</Label>
          </div>
        ))}
      </div>
    </>
  );
}
