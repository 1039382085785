import React, { MouseEvent, useMemo, useState } from "react";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";
import { clsx } from "clsx";

import { Tag } from "common/components/ui/Tag";
import { Button } from "common/components/ui/Button";
import Checkbox from "common/components/ui/Checkbox";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "common/components/ui/Accordion";
import SearchInput from "common/components/SearchInput";

import { FacetParam, SelectedFacetParam } from "../../types";

const initialVisibleCount = 10;
export default function SearchFacet({
  label,
  allFacets,
  selectedFacets,
  toggleSearchParam,
  resetFacet,
  isLoading,
  toggleAccordion,
}: {
  label: string;
  allFacets: FacetParam[];
  selectedFacets: SelectedFacetParam[];
  toggleSearchParam: (facetParam: FacetParam) => void;
  resetFacet: () => void;
  isLoading: boolean;
  toggleAccordion: () => void;
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  function onAccordionTriggerClick(e: MouseEvent<HTMLButtonElement>) {
    if (
      !e.nativeEvent
        .composedPath()
        .some((element) => (element as Element).tagName === "BUTTON")
    ) {
      toggleAccordion();
    }
  }

  // sort selected facets to the top of the list
  const sortedFacets = useMemo(() => {
    if (allFacets.length) {
      const facets = [...allFacets];
      [...selectedFacets].reverse().forEach((facet) => {
        // remove selected facet by index
        const removalIndex = facets.findIndex(({ id }) =>
          Array.isArray(facet.id) && Array.isArray(id)
            ? facet.id.every((string) => id.includes(string))
            : facet.id === id,
        );
        const [removedFacet] = facets.splice(removalIndex, 1);
        // add to beginning of the list
        facets.unshift(removedFacet);
      });
      return facets;
    }
    return selectedFacets.map((facet) => ({ ...facet, target_count: 0 }));
  }, [allFacets, selectedFacets]);

  const filteredFacets = sortedFacets.filter(
    ({ name }, index) =>
      !searchValue ||
      index < selectedFacets.length ||
      name.toLowerCase().includes(searchValue.toLowerCase()),
  );
  const shownOptions = isExpanded
    ? filteredFacets
    : filteredFacets.slice(0, initialVisibleCount);

  return (
    <AccordionItem value={label}>
      <AccordionTrigger className="h-11" onClick={onAccordionTriggerClick}>
        <span className="text-button-14">{label}</span>
        {!!selectedFacets.length && (
          <>
            <Tag variant="primary-black">{selectedFacets.length}</Tag>
            <Button
              className="ml-auto"
              size="sm"
              variant="quaternary-danger"
              onClick={resetFacet}
            >
              Clear all
            </Button>
          </>
        )}
      </AccordionTrigger>
      <AccordionContent className="flex flex-col">
        <SearchInput
          className="mb-2"
          placeholder={`Search ${label}`}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onClear={() => setSearchValue("")}
        />
        {!shownOptions.length && searchValue ? (
          <span>No Results Found for “{searchValue}”</span>
        ) : (
          shownOptions.map((facetParam) => {
            const { name, target_count: targetCount, id } = facetParam;
            const isSelected = selectedFacets.some((selectedFacet) =>
              Array.isArray(selectedFacet.id) && Array.isArray(id)
                ? selectedFacet.id.every((string) => id.includes(string))
                : selectedFacet.id === id,
            );
            const isDisabled = isLoading && !isSelected;
            return (
              <button
                type="button"
                key={id.toString()}
                onClick={() => toggleSearchParam(facetParam)}
                className={clsx(
                  "flex items-center gap-x-1.5 p-2",
                  isDisabled && "opacity-30",
                )}
              >
                <Checkbox checked={isSelected} isStyleOnly />
                <span className="text-button-14">{name}</span>
                <span className="ml-auto text-black-400">{targetCount}</span>
              </button>
            );
          })
        )}
        {sortedFacets.length > initialVisibleCount &&
          (isExpanded ? (
            <Button
              className="mt-2"
              variant="quaternary-black"
              size="sm"
              onClick={() => setIsExpanded(false)}
              leftIcon={<CaretLeft />}
            >
              Show less
            </Button>
          ) : (
            <Button
              className="mt-2"
              variant="quaternary-black"
              size="sm"
              onClick={() => setIsExpanded(true)}
              rightIcon={<CaretRight />}
            >
              Show all ({filteredFacets.length})
            </Button>
          ))}
      </AccordionContent>
    </AccordionItem>
  );
}
