import React, { InputHTMLAttributes } from "react";
import { cva, VariantProps } from "class-variance-authority";
import { Check } from "@phosphor-icons/react";

import { cn } from "common/helpers/utils";

const checkboxVariants = cva(
  "shrink-0 appearance-none border-2 border-black-400 transition-[border-color,background-color] checked:border-black-950 checked:bg-black-950 disabled:bg-black-300",
  {
    variants: {
      size: {
        sm: "size-3.5 rounded",
        md: "size-4 rounded-[5px]",
      },
    },
    defaultVariants: {
      size: "md",
    },
  },
);
type RadioButtonProps = Omit<InputHTMLAttributes<HTMLInputElement>, "size"> &
  VariantProps<typeof checkboxVariants> & { isStyleOnly?: boolean };

export default function Checkbox({
  size = "md",
  checked,
  className,
  isStyleOnly = false,
  disabled,
  ...rest
}: RadioButtonProps) {
  const checkIcon = (
    <Check
      size={size === "md" ? 12 : 10}
      weight="bold"
      className="absolute text-white"
    />
  );
  if (isStyleOnly) {
    return (
      <div
        className={cn(
          checkboxVariants({ size }),
          checked && "border-black-950 bg-black-950",
          className,
          "flex items-center justify-center",
        )}
        {...rest}
      >
        {checkIcon}
      </div>
    );
  }
  return (
    <span className="flex items-center justify-center">
      <input
        className={cn(checkboxVariants({ size }), className)}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        {...rest}
      />
      {checkIcon}
    </span>
  );
}
