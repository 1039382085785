import React from "react";

import { subscriptionStatuses } from "common/constants";
import { typedObjectEntries } from "common/helpers/utils";
import useAppStore from "common/hooks/useAppStore";
import RadioButton from "common/components/ui/RadioButton";
import Label from "common/components/ui/Label";

export default function SubscriptionStatus() {
  const subscriptionStatus = useAppStore(
    (state) => state.debugOptions.subscriptionStatus,
  );
  return (
    <>
      <span className="my-2 text-caption-12-regular text-black-400">
        Subscription Status
      </span>
      <div className="flex flex-wrap gap-2">
        <div className="flex gap-x-2">
          <RadioButton
            id="defaultSubscription"
            onChange={() =>
              useAppStore
                .getState()
                .setDebugOptions({ subscriptionStatus: null })
            }
            name="subscriptionStatus"
            checked={!subscriptionStatus}
          />
          <Label htmlFor="defaultSubscription">Default</Label>
        </div>
        {typedObjectEntries(subscriptionStatuses).map(([key, status]) => (
          <div className="flex gap-x-2" key={key}>
            <RadioButton
              key={key}
              id={key}
              onChange={() =>
                useAppStore
                  .getState()
                  .setDebugOptions({ subscriptionStatus: key })
              }
              name="subscriptionStatus"
              checked={subscriptionStatus === key}
            />
            <Label htmlFor={key}>{status}</Label>
          </div>
        ))}
      </div>
    </>
  );
}
