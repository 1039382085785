import React from "react";

import useAppStore from "common/hooks/useAppStore";
import RadioButton from "common/components/ui/RadioButton";
import Label from "common/components/ui/Label";

const options: {
  label: string;
  value: "LoggedIn" | "LoggedOut" | "NeverLoggedIn";
}[] = [
  { label: "Default", value: null },
  { label: "Logged In", value: "LoggedIn" },
  { label: "Logged Out", value: "LoggedOut" },
  { label: "Never Logged In", value: "NeverLoggedIn" },
];
export default function AccountState() {
  const accountState = useAppStore((state) => state.debugOptions.accountState);
  return (
    <>
      <span className="my-2 text-caption-12-regular text-black-400">
        Account State
      </span>
      <div className="flex flex-wrap gap-2">
        {options.map(({ label, value }) => (
          <div className="flex gap-x-2" key={value}>
            <RadioButton
              id={value}
              onChange={() =>
                useAppStore.getState().setDebugOptions({ accountState: value })
              }
              name="accountState"
              checked={accountState === value}
            />
            <Label htmlFor={value}>{label}</Label>
          </div>
        ))}
      </div>
    </>
  );
}
