import React, { InputHTMLAttributes } from "react";
import { cva, VariantProps } from "class-variance-authority";

import { cn } from "common/helpers/utils";

const radioVariants = cva(
  "appearance-none rounded-full border-2 border-black-400 transition-[border-color] checked:border-[5px] checked:border-black-950",
  {
    variants: {
      size: {
        sm: "size-4",
        md: "size-5",
      },
    },
    defaultVariants: {
      size: "md",
    },
  },
);
type RadioButtonProps = Omit<InputHTMLAttributes<HTMLInputElement>, "size"> &
  VariantProps<typeof radioVariants> &
  (
    | { children?: string; isStyleOnly?: false }
    | { children?: never; isStyleOnly?: true }
  );

export default function RadioButton({
  size,
  checked,
  className,
  isStyleOnly = false,
  ...rest
}: RadioButtonProps) {
  if (isStyleOnly) {
    return (
      <div
        className={cn(
          radioVariants({ size }),
          checked && "border-[5px] border-black-950",
          className,
        )}
        {...rest}
      />
    );
  }
  return (
    <input
      className={cn(radioVariants({ size }), className)}
      type="radio"
      checked={checked}
      {...rest}
    />
  );
}
