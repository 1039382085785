import React from "react";
import { Skull } from "@phosphor-icons/react";

import SubscriptionRole from "common/components/Layout/DesktopLayout/DebugPopup/SubscriptionRole";
import { Button } from "common/components/ui/Button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";

import SubscriptionStatus from "./SubscriptionStatus";
import AccountState from "./AccountState";
import MiscOptions from "./MiscOptions";

export default function DebugPopup() {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          size="lg"
          intent="iconOnly"
          variant="quaternary-black"
          aria-label="support"
        >
          <Skull weight="fill" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex flex-col p-4" align="end">
        <MiscOptions />
        <SubscriptionStatus />
        <SubscriptionRole />
        <AccountState />
      </PopoverContent>
    </Popover>
  );
}
